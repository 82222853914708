import React from "react"
import { Link } from 'gatsby'
import Popover from "../components/popover"
import Img from "../components/image-transform"

const ImageWithPopover = ({content}) => {
  return (
    <div className="section-image-with-popover">
      <div className="container">
        <div className="text-wrapper">
          <h2 className="title">{content.title}</h2>
          <p className="description">{content.description}</p>
        </div>
      </div>

      <div className="content">           
        <div className="image-wrapper">
          <div className="image-with-popover">
            {
              content.svg ?
              <img src={content.image} className="image" alt={content.imageName}/>
              :
              <Img filename={content.image} className="image" alt={content.imageName}/>
            }
            <div className="popovers">
              { content.popover &&
                content.popover.map ((popoverContent, i) => {
                  return (
                    <Popover key={i} content={popoverContent.popoverContent} />
                  )
                })
              }  
            </div>
          </div>
        </div>
      </div>

      {
        content.brandingButton && 
        <div className="button-wrapper">
          <Link to="/product/creating/brand-identity" className="btn btn-black btn-brand"><img src={require('../images/magic-wand.svg')} className="white-magic-wand" alt="magic wand"/>See this with your branding</Link>
        </div>
      }
    </div> 
  )
}

export default ImageWithPopover